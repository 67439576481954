import React from 'react';
import { Link } from 'react-router-dom';
import classes from './navbar.module.css';
import Navlink from './navlink';
import Logo from "../../assets/images/logo-stti.svg"
import Logout from "../../assets/images/deconnexion.png"
import User from "../../assets/images/user.png"

export default function Navbar(props){
    let getUser = JSON.parse(localStorage.getItem('USER'));
    return(
        <nav className={classes.root}>
            <div className={classes.wrapper}>
                <img src={Logo} className={classes.logo} />
                {
                    props.location.pathname.includes('tracking') && !props.isLog ? null : (
                        props.isLog ? (
                            <div>
                                <p className='espaceClient'>STTI Manager</p>
                                <div className='containerNav'>
                                    <div className={classes.nav_links}>
                                        <Navlink url="/orders" label="Commandes" />
                                        <Navlink url="/dashboard" label="Tableau de bord" /> 
                                        {
                                            getUser?.seeStock ?
                                                <Navlink url="/stocks" label="Stocks" />
                                            : null
                                        }
                                        
                                        {/* <Navlink url="/factures" label="Factures" disabled /> */}
                                        {
                                            getUser?.userGroup.code === "superadmin" ?
                                                <Navlink url="/customers" label="Gestion utilisateurs"/>
                                            : null
                                        }
                                        {/* <Navlink url="/tracking" label="Tracking"/>                */}
                                    </div>
                                    
                                    <div className='containerUser'>
                                        <img src={User} className={classes.user} />
                                        <p className='userName'>{getUser?.firstname} {getUser?.lastname}</p>
                                    </div>
                                
                                    <Link onClick={() => props.unlog()} to="/login" className={classes.clientLink}>
                                        <img src={Logout} className={classes.logout}/>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <Link to="/login" className={classes.clientLink}>
                                STTI Manager
                            </Link>
                        )
                    )
                }
            </div>
        </nav>
    )
}