import classes from './defaultCard.module.css'

export default function DefaultCard(props){
    const {children,backgroundColor,color,border,...restProps} = props;

    return(
        <div className={classes.root} style={{backgroundColor:backgroundColor&&backgroundColor,color:color&&color,border:border&&border}} {...restProps}>
            {children}
        </div>
    )
}