import classes from './address.module.css';

export default function Address(props){
    const {name,address,city,zip,country,tel, ...restProps} = props;
    return(
        <div className={classes.address} {...restProps}>
            <p className={classes.name}>{name}</p>
            <p>{address}</p>
            <p>
                {`${zip ? `${zip},`: ''} 
                ${city ? `${city},` : ''}
                ${country ?? ''}`}
            </p>
            <p>{tel}</p>
        </div>
    )

}