import classes from './buttonSwitch.module.css';


export default function ButtonSwitch(props){
    const {
        label,
        onClick,                             
        active,
        ...restProps
    } = props;
        
    return(
        <button className={active ? classes.buttonActive : classes.buttonDisabled} onClick={onClick} {...restProps}>            
            {label}
        </button>
    )
}