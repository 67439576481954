import DefaultCard from "../../components/Cards/defaultCard"
import classes from './transport.module.css';
import ReactECharts from 'echarts-for-react'; 
import { Map } from "../../components/MapCharts/map";

const Transport = props=>{
  const {stats}=props

  const createData = ()=>{
    return Object.keys(stats.quantiteExpediee.detail).map((q)=>{
      return {
        value:stats.quantiteExpediee.detail[q],
        name: q ? q :"No name"
      }
    })
  }    

  var costSeries = []

  Object.keys(stats.cost).map((e) => {
    costSeries.push({
      name: e,
      type: 'bar',                   
      barWidth: '10%',
      data: [stats.cost[e]],        
    })
  })

  const expeditionOption = {
    tooltip: {
      trigger: 'item',
      formatter: `{b} <br /> {c}`,
      extraCssText: 'text-align:center;'
    },
    legend: {
      orient: 'vertical',
      top: "center",
      icon:"circle",
      itemGap: 10,
      right:0,
      textStyle:{
        fontSize:16
      }
    },    
    series: [
      {        
        type: 'pie',
        radius: ['70%', '100%'],
        center: ['35%', '50%'],
        avoidLabelOverlap: false,                
        labelLine: {
          show: false
        },        
        label: {
          show: false,      
        },
        data: createData()
      }
    ]
  };

  const coutOption = {        
    xAxis: {
      type: 'category',
      data: ['Activité']
    },
    yAxis: {
      type: 'value',
    },
    tooltip: {
      trigger: 'item',
      formatter: `{a} <br /> {c}`,
      extraCssText: 'text-align:center;'
    },
    series: costSeries
  };

    return (
        <div className={classes.root} >
            <div className={classes.container} >
                <DefaultCard >
                    <h3>Commandes expédiées par activité</h3>
                    <div className={classes.chartContainer}>
                      <ReactECharts  option={expeditionOption} /> 
                    </div>
                </DefaultCard>
                <DefaultCard>
                    <h3>Coût moyen ht à l'expédition</h3>
                    <div className={classes.chartContainer}>
                      <ReactECharts option={coutOption} /> 
                    </div>
                </DefaultCard>
            </div>
            <Map stats={stats.livraison}/>
        </div>
    )
}

export default Transport