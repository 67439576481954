import classes from './trackingStep.module.css';
import DefaultCard from '../../components/Cards/defaultCard';
import LadSma from './components/lad-sma';

export default function TrackingStep(props){
    return(
        <div className={classes.root}>
            <div className={classes.card_root}>
                <DefaultCard style={{ display: 'flex', paddingTop: 50 }}>
                    <LadSma statusTracking={props.statusTracking}/>
                    {/* {
                        props.typeOrder === "lad-sma" ?
                            <LadSma statusTracking={props.statusTracking}/>
                        :   null
                    }

                    {
                        props.typeOrder === "mes" ?
                            <Mes statusTracking={props.statusTracking}/>
                        :   null
                    }

                    {
                        props.typeOrder === "trans" ?
                            <Trans statusTracking={props.statusTracking}/>
                        :   null
                    } */}
                </DefaultCard>
            </div>
        </div>
    )
}