import Searchbar from '../Searchbar';
import classes from './topPanel.module.css'

export default function TopPanel(props){
    const {title,searchHandler,searchPlaceholder,description,searchDefaultValue} = props;

    return(
        <div className={classes.root}>
            <div className={classes.heading}>
                <h1 className={classes.title}>{title}</h1>
                <p className={classes.description}>{description}</p>
            </div>

            {searchHandler ? 
                <Searchbar placeholder={searchPlaceholder} onSubmit={searchHandler} defaultValue={searchDefaultValue}/>
            : null}
            
        </div>
    )

}