import React,{useState,useEffect} from 'react';
import {fetchShell} from '../../utils/fetchApi';

export default function Customer(props){
    const [loading,setLoading] = useState(false);
    const [customer,setCustomer] = useState(null);

    const getCustomer = async() => {
        let variables = {
            
                shellDatas: [
                    {
                        identifier : "CLIENT_CODE", 
                        locale : "",
                        value: "CCP", 
                        option:""
                    }
                ]
            
        }
        
        let customer = fetchShell('CLIENT',variables);

        setCustomer(customer?.collection[0])
        
    }

    useEffect(()=>{
        getCustomer();
        props.logout(true, false);
    },[])

    return(
        <div>
            <h1>Client</h1>

            {loading ?
                <p>Chargement</p>
                :
                <div className='ordersGrid'>
                    test
                </div>    
            }

            

        </div>
    )
}