import Button from "../Button/button";
import classes from './searchbar.module.css'
import { Search } from "react-feather";

import {useState} from 'react'
export default function Searchbar(props){
    const {placeholder,onChange,onSubmit,defaultValue} = props;

    const [value,setValue] = useState(defaultValue ?? '');

    const handleChange = (e) =>{
        if(onChange){
            onChange(e,'search')
        }
        setValue(e.target.value);
    }
    return(
        <form onSubmit={onSubmit} className={classes.root}>
            <input placeholder={placeholder} name="search" className={classes.input} value={value} onChange={handleChange}/>
            <Button type="submit" label="Rechercher" icon={<Search size="1rem"/>} />
        </form>
    )
}