import { Link,useLocation } from "react-router-dom";
import classes from './navlink.module.css'

export default function Navlink(props){
    const {url,label,disabled} = props;
    const {pathname} = useLocation();

    const linkClass = disabled 
        ? classes.disabled 
        : pathname.includes(url) 
            ? classes.root_current 
            : classes.root;

    return(
        <Link to={url} className={linkClass}>{label}</Link>
    )
}