import { gql } from "@apollo/client";

//----- Récupération Curent User
export const GET_CURRENT_USER = gql`
{
  meSttiUser {
   id
   firstname
   lastname
   email
   userGroup{
    id
    code
   }
   seeStock
  }
 }
`

//----- Récupération des utilisateurs
export const GET_USERS = gql`
{
  allSttiUsers {
    id
    email
    firstname
    lastname
    lastLogin
    userGroup {
      id
      code
    }
    seeStock
  }
}
`;

//----- Supprimer un utilisateur
/*
 Exemple de variables
  {
    "id" : "/api/users/2"
  }
*/
export const DELETE_USER = gql`
mutation DeleteUser($id: ID!){
	deleteUser(input: {id:$id}){
    user{
      id
    }
  }  
}`;