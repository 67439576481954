import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Bars } from "react-loader-spinner";

import classes from './loader.module.css'

export default function Loader(props){
    const {label, color = '#004A95', ...restProps} = props
    return(
        <div className={classes.root}>
            <div style={{color}} className={classes.label}>{label}</div>
            <div className={classes.loader_root}>
                <Bars width={40} height={40} color={color}/>
            </div>
        </div>
    )
}